/* eslint-disable import/no-import-module-exports */
import { hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import AuthenticationFlowApp from './Components/App/AuthenticationFlowApp';

const container = (document as Document).getElementById('mysurprise-auth-flow');

const { MYSURPRISE_AUTH_FLOW_STATE } = window as Window;

if (container && MYSURPRISE_AUTH_FLOW_STATE) {
    hydrateRoot(container, <AuthenticationFlowApp preloadedState={MYSURPRISE_AUTH_FLOW_STATE}/>);
}
