import type { FC } from 'react';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import WebshopContextProvider from '../../../../general/WebshopContext/WebshopContextProvider';
import AuthenticationFlow from '../../../Shared/Components/AuthenticationFlow/AuthenticationFlow';
import Router from '../../../Shared/Components/Router/Router';
import AuthFlowStateProvider from '../../../Shared/Provider/AuthFlowStateProvider/AuthFlowStateProvider';
import AuthFlowPageLayout from '../PageLagout/AuthFlowPageLayout';
import { AuthFlowEnvironment } from '../../../Shared/AuthFlowEnvironment';
import createQueryClient from '../../../../general/query/QueryClientFactory';
import type { MySurpriseAuthFlowState } from '../../../../../ssr/src/Outlet/MySurpriseAuthFlow/MySurpriseAuthFlowSchema';

const queryClient = createQueryClient();

interface Props {
    preloadedState: MySurpriseAuthFlowState;
}

const AuthenticationFlowApp: FC<Props> = ({ preloadedState }) => {
    const { basePath, path, siteMetaData } = preloadedState;

    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <WebshopContextProvider data={siteMetaData}>
                    <AuthFlowStateProvider preloadedState={preloadedState} authFlowEnvironment={AuthFlowEnvironment.AUTH_PAGE}>
                        <Router basePath={basePath} path={path}>
                            <div className="section--flex f-jc--center">
                                <AuthenticationFlow layoutComponent={<AuthFlowPageLayout/>} />
                            </div>
                        </Router>
                    </AuthFlowStateProvider>
                </WebshopContextProvider>
            </QueryClientProvider>
        </React.StrictMode>
    );
};

export default AuthenticationFlowApp;
