import type { FC, PropsWithChildren } from 'react';
import { StaticRouter, BrowserRouter } from 'react-router';
import { window } from '../../../../../js/globals';

interface Props {
    basePath: string;
    path: string;
}

const Router: FC<PropsWithChildren<Props>> = ({ basePath, children, path }) => {
    if (window) {
        return (
            <BrowserRouter basename={basePath}>
                {children}
            </BrowserRouter>
        );
    }

    return (
        <StaticRouter location={path} basename={basePath}>
            {children}
        </StaticRouter>
    );
};

export default Router;
