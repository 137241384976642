import type { FC } from 'react';
import { Outlet } from 'react-router';
import classNames from 'classnames';
import Button, { Size } from '../../../../general/Button/Button';
import styles from './AuthFlowPageLayout.module.scss';
import useAuthNavigation from '../../../Shared/Hooks/UseAuthNavigation';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import Translation from '../../../../general/Translation/Translation';

const AuthFlowPageLayout: FC = () => {
    const { goBack, hasPreviousRoute } = useAuthNavigation();

    return (
        <div className={styles.layout}>
            <div>
                { hasPreviousRoute && (
                    <Button
                        className={classNames('mb-2', styles.back)}
                        onClick={goBack}
                        size={Size.sm}
                        aria-label="back"
                        isClean
                    >
                        <div className={styles.content}>
                            <Icon name='fa-arrow-left-long' />
                            <span><Translation pageString="auth" stringId="back_button"/></span>
                        </div>
                    </Button>
                )}
            </div>

            <Outlet/>
        </div>
    );
};

export default AuthFlowPageLayout;
